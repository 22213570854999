<template>
  <el-dialog
    title="Animais do Romaneio"
    lock-scroll
    v-model="shouldShow"
    width="95%"
  >
    <el-row type="flex" justify="start"
      ><h5 class="mb-0">Dados do romaneio</h5></el-row
    >
    <el-descriptions class="margin-top" :column="4" size="small" border>
      <el-descriptions-item>
        <template #label> Produtor </template>
        {{ provider?.name || "desconhecido" }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Peso Aferido </template>
        {{
          numberFormatter.format(
            packing?.animals
              ?.map((a) => a.carcasses)
              .flat()
              ?.reduce((t, l) => (t += Number(l?.weight)), 0) || 0
          ) || 0
        }}
        kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Peso Descrito </template>
        {{
          numberFormatter.format(
            packing?.animals?.reduce(
              (t, e) => (t += Number(e.weight) + Number(e.weight_)),
              0
            ) || 0
          )
        }}
        kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Carcaças </template>
        {{
          packing?.animals?.reduce((t, a) => (t += a?.carcasses?.length), 0) ||
          0
        }}
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      v-if="showAnimalPriceUpdate"
      :column="3"
      size="small"
      border
    >
      <el-descriptions-item>
        <template #label> Preços </template>
        {{
          provider?.prices
            ?.map((p) => `${p.standard}: ${p.formatted_price}`)
            .join("; ")
        }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> Taxa de Abate </template>
        {{
          Number(provider?.tax || 0)
            ?.toFixed(2)
            ?.replace(".", ",")
        }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label> % Desconto </template>
        {{
          Number(provider?.deduction || 0)
            ?.toFixed(2)
            ?.replace(".", ",")
        }}
        %
      </el-descriptions-item>
    </el-descriptions>

    <el-row type="flex" justify="start"><h5 class="mb-0">Animais</h5></el-row>

    <el-table
      :data="Animals"
      cell-class-name="p-1 centralized-cell"
      v-loading="isLoadingCarcasses"
      empty-text="Nenhum animal foi cadastrado no romaneio"
    >
      <el-table-column label="Nº" prop="code">
        <template #default="r" v-if="packing_?.status != 'completed'">
          <base-input
            mask="#*"
            v-model="r.row.code"
            v-on:keyup.enter="updateAnimal(r.row)"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column label="peso descrito" v-if="!showAnimalPriceUpdate">
        <template #default="r">
          <base-input
            type="money"
            :minimumFractionDigits="3"
            v-model="r.row.weight"
            v-on:keyup.enter="updateAnimal(r.row)"
          >
          </base-input>
          <base-input
            type="money"
            v-model="r.row.weight_"
            :minimumFractionDigits="3"
            v-on:keyup.enter="updateAnimal(r.row)"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column
        label="soma"
        :formatter="
          (r) =>
            `${numberFormatter.format(
              Number(r?.weight) + Number(r?.weight_ || 0) || 0
            )} kg.`
        "
      ></el-table-column>

      <el-table-column
        label="carcaças"
        :formatter="(r) => `${r.carcasses?.length || 0}`"
      >
      </el-table-column>
      <el-table-column
        prop="standard"
        label="qualidade"
        v-if="showAnimalPriceUpdate"
      >
        <template #default="c" v-if="packing_?.status != 'completed'">
          <el-select
            v-model="c.row.standard"
            size="medium"
            @change="(q) => setAnimalStandard(c.row, q)"
          >
            <el-option
              v-for="item in Standards"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="preço" v-if="showAnimalPriceUpdate">
        <template #default="r">
          <base-input
            type="money"
            v-model="r.row.price"
            v-on:keyup.enter="updateAnimal(r.row)"
          ></base-input>
        </template>
      </el-table-column>
      <el-table-column
        label="peso aferido"
        :formatter="
          (r) =>
            `${numberFormatter.format(
              r?.carcasses?.reduce((t, c) => (t += Number(c.weight)), 0) ||
                0 ||
                0
            )} kg.`
        "
      ></el-table-column>
      <el-table-column label="ações">
        <template #default="c">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-button
                v-if="showAnimalPriceUpdate"
                onlyIcon
                :type="c.row.user ? 'success' : 'warning'"
                @click="
                  () => (c.row.user ? cantCheckTwice() : checkAnimal(c.row))
                "
              >
                <i class="el-icon-finished"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                @click="openPackingListCheckModal(c.row)"
              >
                <i class="el-icon-connection"></i
              ></el-button>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <el-row
        :justify="
          showCheckedBy || ['checking', 'completed'].includes(packing_?.status)
            ? 'space-between'
            : 'end'
        "
        type="flex"
      >
        <h6
          v-if="showCheckedBy || packing_?.status === 'completed'"
          class="text-grey"
        >
          Conferido por:
          {{ AnimalsUsersCheckers }}
        </h6>
        <el-button
          v-else-if="packing_?.status === 'checking'"
          type="warning"
          plain
          @click="
            () =>
              updatePacking((packing_.status = 'created')) |
              $emit('close-modal')
          "
          >Devolver para montagem</el-button
        >
        <el-button type="text" class="button" @click="shouldShow = false"
          >Fechar</el-button
        >
      </el-row>
    </template>
    <packing-list-carcasses-modal
      :showModal="showPackingListCheckModal"
      :packing="packing"
      :animal="animal"
      :providers="providers"
      @close-modal="showPackingListCheckModal = false"
      @should-update="$emit('should-update')"
    ></packing-list-carcasses-modal>
  </el-dialog>
</template>
<script>
import { ElNotification } from "element-plus";
import BaseInput from "../../components/BaseInput.vue";
import PackingListCarcassesModal from "./PackingListCarcassesModal.vue";

export default {
  props: [
    "packing",
    "showModal",
    "providers",
    "showAnimalPriceUpdate",
    "showCheckedBy",
  ],
  components: { BaseInput, PackingListCarcassesModal },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      packing_: null,
      animal: null,
      showPackingListCheckModal: false,
      carcasses: null,
      provider: null,
      isLoadingCarcasses: false,
      isLoadingSave: false,
      isLoadingSearch: true,
      numberFormatter: new Intl.NumberFormat("pt-BR", {
        style: "decimal",
      }),
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
      intervalPooling: null,
    };
  },
  mounted() {
    this.fetchStandards();
  },
  watch: {
    packing(v) {
      this.packing_ = { ...v };

      if (v?.provider?.uid && v.provider.uid != this?.provider?.uid) {
        this.provider = v.provider;
        this.fetchProvider(v?.provider?.uid);
      }

      if (this.animal)
        this.animal = this.packing?.animals?.find(
          (a) => a.uid === this.animal.uid
        );
      if (
        this?.animal?.carcasses?.length &&
        this.animal?.standard !=
          this.animal?.carcasses?.find((e, i) => i === 0)?.standard
      ) {
        this.setAnimalStandard(this.animal, this.animal.carcasses[0].standard);
      }
    },
    showModal(v) {
      if (v) this.fetchCarcasses();
    },
  },
  computed: {
    Standards() {
      return this.standards?.map((s) => s.value) || [];
    },
    AnimalsUsersCheckers() {
      const names = {};

      return (
        this.packing?.animals
          ?.map((a) => a?.user)
          ?.map((u) => u?.shortname)
          ?.filter((name) => (name in names ? false : (names[name] = true)))
          ?.join(",") || "usuário do sistema"
      );
    },
    AnyAnimalHasLessThan2SelectedCarcasses() {
      return this.packing_?.animals?.some((a) => a?.carcasses?.length < 2);
    },
    AnyPriceWasUpdatedAfterSetStandard() {
      return this.packing_?.animals?.some(
        (a) =>
          a.price !=
          (this.provider?.prices?.find((p) => p.standard === a.standard)
            ?.price || a.price)
      );
    },
    AnimalWeightAndCarcassesWeightDifferMoreThanThreePercent() {
      const sumWeightAnimals = this.packing_?.animals?.reduce(
        (t, a) => (t += (Number(a.weight) || 0) + (Number(a.weight_) || 0)),
        0
      );
      const sumWeightCarcasses = this.packing_?.animals
        ?.map((a) => a?.carcasses)
        ?.flat()
        ?.reduce((t, c) => (t += Number(c.weight)), 0);

      return (
        Math.abs(
          ((sumWeightAnimals - sumWeightCarcasses) / sumWeightAnimals) * 100
        ) >= 3
      );
    },
    UncheckedAnimals() {
      return this.packing?.animals?.filter((a) => !a.checked_by) || [];
    },
    Animals() {
      return this.packing?.animals || [];
    },
    isNew() {
      return !this.packing;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    openPackingListCheckModal(animal) {
      this.animal = animal;
      this.showPackingListCheckModal = true;
    },
    async checkAnimal(animal) {
      var shouldUpdateAnimal = true;
      var shouldUpdatePacking;
      if (!animal?.carcasses?.length)
        shouldUpdateAnimal = await this.$confirm(
          "Sem carcaças não será possível calcular o peso aferido do animal",
          "Conferir animal sem carcaças?",
          {
            confirmButtonText: "Conferir",
            cancelButtonText: "Cancelar",
            type: "warning",
          }
        )
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });

      if (!animal.user) {
        if (this.UncheckedAnimals?.length == 1)
          shouldUpdatePacking = await this.$confirm(
            "Você está conferindo o último animal do romaneio, ele irá para a aba de conferidos, confirma?",
            "Conferir o romaneio?",
            {
              confirmButtonText: "Conferir",
              cancelButtonText: "Cancelar",
              type: "warning",
            }
          )
            .then(() => {
              return true;
            })
            .catch(() => {
              return false;
            });
      }
      //var updateAnimalResult;
      if (shouldUpdateAnimal) {
        animal.checked_by = this.$store?.state?.user?.uid;
        await this.updateAnimal(animal);
      }

      if (shouldUpdatePacking) {
        this.packing_.status = this.getPackingStatus();
        this.updatePacking();
        this.$emit("close-modal");
      }
    },
    getPackingStatus() {
      var status = "checked";

      if (this.AnyPriceWasUpdatedAfterSetStandard) {
        this.notifyPackingInAnalisys(
          "O preço de um animal foi alterado manualmente"
        );
        status = "analysis";
      }

      if (this.AnyAnimalHasLessThan2SelectedCarcasses) {
        this.notifyPackingInAnalisys(
          "Um animal possui apenas 1 ou nenhuma carcaça selecionada"
        );
        status = "analysis";
      }

      if (this.AnimalWeightAndCarcassesWeightDifferMoreThanThreePercent) {
        this.notifyPackingInAnalisys(
          "O peso declarado e o peso aferido do romaneio diferem em mais de 5%"
        );
        status = "analysis";
      }

      return status;
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    isCarcassSelected(carcass) {
      if (carcass?.uid)
        return (
          this.packing_?.list?.some((l) => l?.carcass?.uid === carcass?.uid) ||
          false
        );
      return false;
    },
    cantCheckTwice() {
      ElNotification.error({
        title: "Este animal já foi verificado, você não pode checar novamente",
      });
    },
    needAtLeastOneCarcass() {
      ElNotification.error({
        title: "Você deve selecionar ao menos uma carcaça para checar o animal",
      });
    },
    notifyPackingInAnalisys(message) {
      ElNotification.warning({
        title: "O romaneio está em análise",
        message,
      });
    },
    updatePacking() {
      fetch(`${this.$store.state.apiUrl}packings/${this.packing_.uid} `, {
        credentials: "include",
        method: `PUT`,
        body: JSON.stringify({ ...this.packing_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.fetchCarcasses();
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar o vínculo",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchStandards() {
      const url = new URL(`${this.$store.state.apiUrl}system/preferences`);
      url.search = new URLSearchParams({
        key: "defined_standards",
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.standards = e));
    },
    fetchProvider(uid) {
      const url = new URL(`${this.$store.state.apiUrl}partners/${uid}`);
      if (uid)
        fetch(url, {
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            this.isLoadingSearch = false;
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then((e) => (this.provider = e));
    },
    setAnimalStandard(animal, standard) {
      animal.standard = standard;
      this.updateAnimal(animal);
    },
    updateAnimal(animal) {
      return fetch(`${this.$store.state.apiUrl}animals/${animal.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({
          ...animal,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            this.$emit("should-update");
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar o vínculo",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchCarcasses() {
      this.isLoadingCarcasses = true;
      const url = new URL(`${this.$store.state.apiUrl}carcasses`);
      url.search = new URLSearchParams({
        provider_id: this?.packing?.provider?.uid || "",
        packing_id: this?.packing?.uid,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.carcasses = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoadingCarcasses = false));
    },
  },
  name: "CarcassModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
