<template>
  <el-dialog
    title="Selecionar carcaças"
    v-model="shouldShow"
    width="95%"
    lock-scroll
    append-to-body
  >
    <el-row type="flex" justify="start"
      ><h5 class="mb-0">Dados do romaneio</h5></el-row
    >
    <el-descriptions class="margin-top" :column="4" size="small" border>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-user"></i>
          Código
        </template>
        {{ animal?.code || "desconhecido" }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-mobile-phone"></i>
          Peso Aferido
        </template>
        {{ animal?.carcasses?.reduce((t, l) => (t += l.weight), 0) || 0 }}
        Kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-location-outline"></i>
          Peso Descrito
        </template>
        {{ (Number(animal?.weight) || 0) + (Number(animal?.weight_) || 0) }} Kg.
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-tickets"></i>
          Carcaças
        </template>
        {{ animal?.carcasses?.length || 0 }}
      </el-descriptions-item>
    </el-descriptions>

    <el-row type="flex" justify="start"><h5 class="mb-0">Carcaças</h5></el-row>

    <el-table
      :data="Carcasses"
      :key="animal"
      v-loading="isLoadingCarcasses"
      empty-text="Nenhuma carcaça foi cadastrada"
    >
      <el-table-column prop="code" label="Nº"></el-table-column>
      <el-table-column prop="provider.name" label="produtor"></el-table-column>
      <el-table-column
        label="data"
        :formatter="(r) => formatDate(r.bought_at)"
      ></el-table-column>
      <el-table-column prop="weight" label="peso (kg)"></el-table-column>
      <el-table-column prop="type_formatted" label="tipo"></el-table-column>
      <el-table-column prop="standard" label="qualidade"></el-table-column>
      <el-table-column label="vinculado">
        <template #default="c">
          <el-row type="flex" justify="end">
            <el-switch
              :disabled="
                !isCarcassSelected(c?.row) &&
                c.row?.list?.length < c?.row?.total
              "
              :modelValue="isCarcassSelected(c?.row)"
              @update:modelValue="selectCarcass(c.row)"
            ></el-switch>
          </el-row>
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <el-row justify="end" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Fechar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { ElNotification } from "element-plus";

export default {
  props: ["packing", "showModal", "providers", "animal"],
  //components: { PackingListAnimalsModal },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      packing_: null,
      providers_: null,
      carcasses: null,
      isLoadingCarcasses: false,
      isLoadingSave: false,
      isLoadingSearch: true,
      systemActions: [],
      dateFormatter: new Intl.DateTimeFormat("pt-BR", {
        dateStyle: "short",
        timeStyle: "short",
      }),
      intervalPooling: null,
    };
  },
  watch: {
    packing(v) {
      this.packing_ = { ...v };
      if (v?.provider?.uid) this.packing_.provider_id = v.provider.uid;
      this.providers_ = this.providers;
    },
    showModal(v) {
      if (v) {
        this.fetchCarcasses();
      }
    },
  },
  computed: {
    Carcasses() {
      return this.carcasses || [];
    },
    isNew() {
      return !this.packing;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    async selectCarcass(carcass) {
      if (this.isCarcassSelected(carcass)) return this.unselectCarcass(carcass);

      this.updateCarcass(
        {
          uid: carcass.uid,
          provider_id: this.packing?.provider?.uid,
          packing_list_id: this.packing.uid,
          animal_id: this.animal.uid,
        },
        "PUT"
      );
    },
    unselectCarcass(carcass) {
      if (this.isCarcassSelected(carcass))
        this.updateCarcass(
          {
            uid: carcass.uid,
            packing_list_id: null,
            animal_id: null,
          },
          "PUT"
        );
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    isCarcassSelected(carcass) {
      if (carcass?.uid)
        return (
          this.animal?.carcasses?.some((l) => l?.uid === carcass?.uid) || false
        );
      return false;
    },
    updateCarcass(carcass, method) {
      fetch(
        `${this.$store.state.apiUrl}carcasses${
          method != "POST" ? `/${carcass.uid}` : ""
        }`,
        {
          credentials: "include",
          method: `${method}`,
          body: JSON.stringify({ ...carcass }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.fetchCarcasses();
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar o vínculo",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    fetchProviders(queryName) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({
        is_provider: true,
        searchName: queryName,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.providers_ = e));
    },
    fetchCarcasses() {
      this.isLoadingCarcasses = true;
      const url = new URL(`${this.$store.state.apiUrl}carcasses`);
      url.search = new URLSearchParams({
        provider_id: this?.packing?.provider?.uid || "",
        packing_id: this?.packing?.uid,
        animal_id: this?.animal?.uid,
      });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.carcasses = json))
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoadingCarcasses = false));
    },
  },
  name: "PackingListCarcassesModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
</style>